<template>
  <div class="">
    <detail
      v-if="activeDetail"
      :activation="activeDetail"
      :donnes="selectedItem"
      type="Verre"
      @oga="retourDetail"
    />
    <div class="w-full flex text-90 text-c18 font-c6 text-left">
      <div class="w-1/4">
        NOM COMMERCIAL
      </div>
      <div class="w-1/5 ml-4">
        MATIÈRE
      </div>
      <div class="w-1/6 ml-6">
        S/COUCHE
      </div>
      <div class="w-68 ml-4">
        IND.
      </div>
      <div class="w-68 ml-4">
        CYL.
      </div>
      <div class="w-68 ml-4">
        AXE
      </div>
      <div class="w-79 ml-4">
        ADD.
      </div>
      <div class="w-1/6 ml-4">
        PRIX
      </div>
      <div class="w-20 ml-4" />
      <div />
    </div>

    <div class="flex justify-center mt-12" v-if="charge && allVerre.length === 0">
      <easy-spinner
          type="dots"
          size="30"
      />
    </div>

    <div
      v-for="(item, index) in allVerre"
      :key="index"
      class="w-full flex items-center text-c16 font-c4 text-left mt-6 item pt-6 cursor-pointer"
      @click="selected(item)"
    >
      <div class="w-1/4">
       {{item.name.substr(0, 17)}} <span v-if="item.name.length > 17">..</span>
      </div>

      <div class="w-1/5 ml-4">
        {{item.glassMaterial}}
      </div>

      <div class="w-1/6 ml-6">
        <span v-if="item.glassDetails !== null">
          {{item.glassDetails.underCoat}}
        </span>
        <span v-if="!item.glassDetails">
          -
        </span>
      </div>

      <div class="w-68 ml-4">
        {{item.indice}}
      </div>

      <div class="w-68 ml-4">
         <span v-if="item.glassDetails !== null">
          {{item.glassDetails.cylinder}}
        </span>
        <span v-if="!item.glassDetails">
          -
        </span>
      </div>

      <div class="w-68 ml-4">
        <span v-if="item.glassDetails !== null">
          {{item.glassDetails.axis}}
        </span>
        <span v-if="!item.glassDetails">
          -
        </span>
      </div>

      <div class="w-79 ml-4">
       <span v-if="item.glassDetails !== null">
          {{item.glassDetails.additionMax}}
        </span>
        <span v-if="!item.glassDetails">
          -
        </span>
      </div>

      <div class="w-1/6 ml-4 text-yel">
        {{ item.price.toLocaleString() }} Fr
      </div>
      <div class="w-20 ml-4">
        <icon
          :data="icons.option"
          height="25"
          width="25"
          class="icone"
          original
        />
      </div>
    </div>
  </div>
</template>

<script>
import option from '../../../assets/icons/option.svg'
import detail from '../../popup/detailProduct'
import http from "../../../plugins/https"
import apiroutes from "../../../router/api-routes"

export default {
  name: "Index",
  components: {
    detail
  },

  data () {
    return {
      icons: {
        option
      },
      allVerre: [],
      activeDetail: false,
      selectedItem: {
        name: 'test'
      },
      charge: true
    }
  },

  created () {
    this.allProduct()
  },

  methods: {
    retourDetail (answer) {
      this.activeDetail = answer
    },

    selected (item) {
      this.selectedItem = item
      this.activeDetail = true
    },

    allProduct () {
      http.get(apiroutes.baseURL + apiroutes.getProduct + '?type=GLASS')
          .then(response => {
            this.allVerre = response
            this.charge = false
          })
          .catch(error => {
            this.charge = false
            console.log(error)
          })
    },
  },
}
</script>

<style lang="scss" scoped>
.svg-icon{
  fill: transparent;
}
.item{
  border-top: 1px solid #DDDDDD;
  .icone {
    display: none;
  }
}
.item:hover{
 .icone {
   display: block;
 }
}
</style>
