<template>
  <div class="rounded-10 h-auto bg-white w-full p-8 all">
    <div class=" w-full">
      <div
        class="w-full"
        @click="activeGamme = activeMarque = activeDispo = false"
      >
        <steep-select
          v-if="steep.length > 0"
          :option="steep"
          :value="steepValue"
          @info="retourSteep"
        />
      </div>

      <div class="w-3/6 flex mt-12" v-if="steepValue !== 'Prix' && steepValue !== 'Prestations'">
        <!--Disponibilité-->
        <div class="w-1/3">
          <div
            class="filtr flex items-center w-full cursor-pointer pl-4 pr-4"
            @click="activeDispo = ! activeDispo; activeGamme = false; activeMarque = false"
          >
            <div class="flex items-center w-full">
              <div
                class="flex items-center w-full"
              >
                <div class="mr-3 w-4/5 text-left">
                  <span> {{ dispoValue.substr(0, 14) }}</span> <span v-if="dispoValue.length > 14">...</span>
                </div>

                <div class="w-1/5 flex justify-end">
                  <icon
                    :data="icons.down"
                    height="20"
                    width="10"
                    original
                  />
                </div>
              </div>
            </div>
          </div>

          <selectfiltre
            v-if="activeDispo"
            :option="optionDispo"
            size="16px"
            class="compFiltre"
            @info="retourDispo"
          />
        </div>

        <!--Gamme-->
        <div class="w-1/3 ml-3">
          <div
              v-if="optionGamme.length > 0"
            class="filtr flex items-center w-full cursor-pointer pl-4 pr-4"
            @click="activeGamme = ! activeGamme; activeDispo = false; activeMarque = false"
          >
            <div class="flex items-center w-full">
              <div class="flex items-center w-full">
                <div class="mr-3 w-4/5 text-left">
                  <span class="text-left"> {{ gammeValue.substr(0, 14) }}</span> <span v-if="gammeValue.length > 14">...</span>
                </div>
                <div class="w-1/5 flex justify-end">
                  <icon
                    :data="icons.down"
                    height="20"
                    width="10"
                    original
                  />
                </div>
              </div>
            </div>
          </div>
          <selectfiltre
            v-if="activeGamme"
            :option="optionGamme"
            size="16px"
            class="compFiltre"
            @info="retourGamme"
          />
        </div>

        <!--Marque-->
        <div class="w-1/3 ml-3">
          <div
              v-if="optionMarque.length > 0"
            class="filtr flex items-center w-full cursor-pointer pl-4 pr-4"
            @click="activeMarque = ! activeMarque; activeDispo = false; activeGamme = false"
          >
            <div class="flex items-center w-full">
              <div class="flex items-center w-full">
                <div class="mr-3 w-4/5 text-left">
                  <span class="text-left"> {{ marqueValue.substr(0, 14) }}</span> <span v-if="marqueValue.length > 14">...</span>
                </div>
                <div class="w-1/5 flex justify-end">
                  <icon
                    :data="icons.down"
                    height="20"
                    width="10"
                    original
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="">
            <selectfiltre
              v-if="activeMarque"
              :option="optionMarque"
              size="16px"
              class="compFiltre"
              @info="retourMarque"
            />
          </div>
        </div>
      </div>
    </div>

    <div
      class="mt-12 liste p-6"
      @click="activeGamme = activeMarque = activeDispo = false"
    >
      <verresListe v-if="steepValue === 'Verres'" />
      <monture-liste v-if="steepValue === 'Montures'" />
      <accessoire v-if="steepValue === 'Accessoires'" />
<!--      <prestation v-if="steepValue === 'Prestations'" />-->
<!--      <prix v-if="steepValue === 'Prix'" />-->
    </div>
  </div>
</template>

<script>
import arrow from '../../../assets/icons/arrow.svg'
import steepSelect from '../../helper/form/steepSelect'
import selectfiltre from '../../helper/form/selectFiltre'
import agenda from '../../../assets/icons/agenda.svg'
import down from '../../../assets/icons/down.svg'
import verresListe from './verresListe'
import montureListe from './monture'
import accessoire from './accessoire'
// import prestation from './prestations/prestationTemplate'
// import prix from './prix/prixTemplate'
import apiroutes from '../../../router/api-routes'
import http from '../../../plugins/https'

export default {
  name: "Index",
  components: {
    steepSelect,
    selectfiltre,
    verresListe,
    montureListe,
    accessoire,
    // prestation,
    // prix
  },

  data () {
    return {
      icons: {
        arrow,
        agenda,
        down
      },
      steep: ['Verres', 'Montures', 'Accessoires'],
      steepValue: 'Verres',
      optionDispo: ['Disponible', 'Indisponible'],
      dispoValue: 'Disponibilité',
      activeDispo: false,

      optionGamme: ['Premium', 'Gold'],
      gammeValue: 'Gamme',
      activeGamme: false,

      optionMarque: ['Gucci', 'LV'],
      marqueValue: 'Marque',
      activeMarque: false
    }
  },

  created (){
    this.allGammes()
    this.allMarques()
  },

  methods: {
    retourDispo(answer){
      this.activeDispo = false
      this.dispoValue = answer
    },

    retourSteep(answer){
      this.steepValue = answer
      this.$emit('label', answer)
    },

    retourGamme(answer){
      this.activeGamme = false
      this.gammeValue = answer
    },

    retourMarque(answer){
      this.activeMarque = false
      this.marqueValue = answer
    },

    allMarques () {
      http.get(apiroutes.baseURL + apiroutes.allMarque)
          .then(response => {
            let tab = []
            for (let item in response) {
              tab.push(response[item].label)
            }
            this.optionMarque = tab
            this.charge = false
          })
          .catch(error => {
            this.charge = false
            console.log(error)
          })
    },

    allGammes () {
      http.get(apiroutes.baseURL + apiroutes.allGamme)
          .then(response => {
            let tab = []
            for (let item in response) {
              tab.push(response[item].label)
            }
            this.optionGamme = tab
            this.charge = false
          })
          .catch(error => {
            this.charge = false
            console.log(error)
          })
    }
  },
}
</script>

<style lang="scss" scoped>
.svg-icon{
  fill: transparent;
}
.filtr{
  background: rgba(242, 245, 249, 0.5);
  border: 0.5px solid #C4C4C4;
  box-sizing: border-box;
  border-radius: 5px;
  height: 36.39px;
}
.liste{
  border: 0.480025px solid #CCCFD2;
  box-sizing: border-box;
  border-radius: 4.80025px;
}
.compFiltre{
  width: auto;
  min-width: 9.1%;
}
.all {
  min-height: 600px;
}
</style>
