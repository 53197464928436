<template>
  <div class="">
    <div class="w-full flex text-90 text-c18 font-c6 text-left">
      <div class="w-1/2">
        NOM COMMERCIAL
      </div>
      <div class="w-1/5 ml-2">
        MARQUE
      </div>
      <div class="w-1/5 ml-2">
        GAMME
      </div>
      <div class="w-48 ml-2" />
      <div />
    </div>

    <div class="item mt-6" />

    <div class="flex justify-center mt-12" v-if="charge && allVerre.length === 0">
      <easy-spinner
          type="dots"
          size="30"
      />
    </div>

    <div
      v-for="(item, index) in allVerre"
      :key="index"
      class="mt-6"
    >
      <liste :donnes="item" />
    </div>
  </div>
</template>

<script>
import liste from './montureListe'
import http from "../../../plugins/https"
import apiroutes from "../../../router/api-routes"

export default {
  name: "Index",
  components: {
    liste
  },

  data () {
    return {
      allVerre: [],
      charge: true
    }
  },

  created () {
    this.allProduct()
  },

  methods: {
    allProduct () {
      http.get(apiroutes.baseURL + apiroutes.getProduct + '?type=FRAMES')
          .then(response => {
            this.allVerre = response
            this.charge = false
          })
          .catch(error => {
            this.charge = false
            console.log(error)
          })
    },
  },
}
</script>

<style lang="scss" scoped>
.svg-icon{
  fill: transparent;
}
.item{
  border-top: 1px solid #DDDDDD;
  .icone {
    display: none;
  }
}
.item:hover{
 .icone {
   display: block;
 }
}
</style>
