<template>
  <div class="w-full flex">
    <div
      v-for="(item, index) in option"
      :key="index"
      class="text-c18 text-90 font-c5 pl-5 pr-5 cursor-pointer flex items-center"

      @click="selected(item)"
    >
      <div class="pb-3" :class="{'item': position === item}">{{ item }}</div>

      <div class="h-full pb-3">
        <div class="diviser ml-10 " v-if="index === separator" />
      </div>

    </div>
  </div>
</template>

<script>

export default {
  name: "Index",
  props: {
    option: {
      type: Array,
      default: null
    },
    value: {
      type: String,
      default: ''
    },
    separator: {
      type: Number,
      default: null
    },
  },

  data () {
    return {
      position: null
    }
  },

  created () {
    this.position = this.value
  },

  methods: {
    selected(item){
      this.position = item
      this.$emit('info', item)
    }
  },
}
</script>

<style lang="scss" scoped>
@import "../../../assets/styles/sass/variables.scss";

  .item {
    border-bottom: 3px solid $oho-blue;
    color: $oho-blue
  }
  .diviser {
    width: 1px;
    background-color: black;
    height: 100%;
  }
</style>
