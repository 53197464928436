<template>
  <div class="p-10 pr-16">
    <div
      v-if="!activeAdd"
      class="flex items-center"
    >
      <div class="w-1/2 flex text-left text-c48 items-center font-c6">
        <icon
          :data="icons.back"
          height="70"
          width="70"
          class="mr-2 cursor-pointer"
          original
        />
        <div class="ml-4">
          Articles
        </div>
      </div>

<!--      <div class="w-1/2">-->
<!--        <div class="flex justify-end">-->
<!--          <div class="w-4/6 mr-6">-->
<!--            <bouton-->
<!--              label="Transfère de produits"-->
<!--              radius="5.4px"-->
<!--              weight="600"-->
<!--              background="#B3A9F2"-->
<!--              size="17px"-->
<!--              @oga="retourTransfere"-->
<!--              v-if="etape === 'Montures' || etape === 'Accessoires'"-->
<!--            />-->
<!--          </div>-->
<!--          <div class="w-4/6">-->
<!--            <bouton-->
<!--              :label="buttonLabel"-->
<!--              radius="5.4px"-->
<!--              weight="600"-->
<!--              size="17px"-->
<!--              @oga="retourClick"-->
<!--            />-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
    </div>

    <div
      v-if="!activeAdd"
      class="mt-6"
    >
      <global-view @label="retourEtape"  v-if="!reloadSession"/>
    </div>

  </div>
</template>

<script>
import back from '../../assets/icons/backBut.svg'
import globalView from '../../component/cdevs/product/globalData'

export default {
  name: "Index",

  components: {
    globalView,
  },

  data () {
    return {
      icons: {
        back
      },
      buttonLabel: 'Ajouter un verre',
      etape: 'Verres',
      activeAdd: false,
      activePrestation: false,
      activePrix: false,
      reloadSession: false,
      activeTransfere: false,
      activeAlert: false,
      activeConfirmation: false,
      selectedAgence: null,
      activeSuccess: false,
      message: ''
    }
  },

  methods: {
    retourClick(answer){
      if (answer && this.etape !== 'Prestations' && this.etape !== 'Prix'){
        this.activeAdd = true
      }
      if (this.etape === 'Prestations') {
        this.activePrestation = true
      }
      if (this.etape === 'Prix') {
        this.activePrix = true
      }
    },

    retourTransfere () {
        this.activeTransfere = true
    },

    retourSuccess (answer) {
      this.activeSuccess = answer
    },

    retourConfirmation (answer) {
      if (answer) {
        this.message = 'Transfert effectué vers agence ' + this.selectedAgence.label + ' avec succès.'
        this.activeSuccess = true
      }
      this.activeConfirmation = false
    },

    retourTransfereExec (answer) {
      this.activeTransfere = false
      if (answer) {
        this.selectedAgence = answer
        this.activeConfirmation = true
      }
    },

    retourBack(answer){
      this.activeAdd = answer
    },

    retourPrestation(answer){
      this.activePrestation = answer
    },

    retourPrix(answer){
      if (answer === true){
        this.reloadSession = answer

        setTimeout(() => {
          this.reloadSession = false
        }, 200)
      }
      this.activePrix = false
    },

    retourEtape(answer){
      this.etape = answer

    }
  }
}
</script>

<style lang="scss" scoped>
.svg-icon {
  fill: white;
}
</style>
