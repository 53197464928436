<template>
  <section class="success">
    <popup-base
      v-show="wantToMakeOffer"
      class="popo md:pt-10 md:pb-4 w-full"
      @clickOnBackground="logState"
    >
      <div class="cardinal mr-auto ml-auto p-5">
        <div
          class="success-container h-full pt-6 p-6 pb-12"
        >
          <div class="text-left flex pl-6 pr-6">
            <div class="w-full flex justify-end">
              <icon
                :data="icons.fermer"
                height="30"
                width="30"
                class="cursor-pointer"
                original
                @click="fermer"
              />
            </div>
          </div>

          <div class="text-c18 font-c4 bg-white flex items-center text-left">
            <img
              v-if="donnes.type === 'ACCESSORY'"
              src="../../assets/images/jpg/etuie.jpg"
              alt=""
              class="img"
            >
            <img
              v-if="donnes.type === 'FRAMES'"
              src="../../assets/images/jpg/lunette.jpg"
              alt=""
              class="img"
            >
            <div class="w-2/3 ml-6">
              <div class="text-90 font-c6 text-c16">
<!--                {{ type }}-->
              </div>
              <div class="text-c42 font-c6">
                {{ donnes.name.substr(0, 38) }} <span v-if="donnes.name.length > 38">..</span>
              </div>

              <div class="flex text-c14 mt-10">
                <div>Marque: </div>
                <div class="ml-2 font-c5">
                  {{ donnes.brands[0].label }}
                </div>

                <div class="ml-12" v-if="donnes.ranges.length > 0">
                  Gamme:
                </div>
                <div class="ml-2 font-c5" v-if="donnes.ranges.length > 0">
                  {{ donnes.ranges[0].label }}
                </div>
              </div>

              <div class="flex mt-6">
                <div
                    v-for="(item, index) in donnes.prices"
                    :key="index"
                    class="flex text-c14 mr-4"
                >
                  <div>{{ item.category.label }}:</div>
                  <div class="ml-2 font-c5 text-orange">
                    {{ item.amount.toLocaleString() }} Frs
                  </div>
                </div>

                <div
                    v-if="donnes.prices.length === 0"
                    class="text-c14 text-90"
                >
                  Aucun prix n'a été ajouté pour ce produit
                </div>

              </div>

              <div class="flex text-c14 mt-6">
                <div>Qté dispo: </div>
                <div class="ml-2 text-oho-green">
                  <span v-if="donnes.quantity">{{ donnes.quantity.toLocaleString() }}</span>
                  <span v-if="!donnes.quantity">-</span>
                </div>
              </div>

              <div
                  v-if="donnes.accessories !== null"
                  class="flex mt-6"
              >
                <div class="w-1/4 mr-3">
                  <div class="text-c15 font-c6">Etuis</div>
                  <img src="../../assets/images/jpg/etuie.jpg" alt="" class="access mt-2">
                </div>

                <div class="w-1/4">
                  <div class="text-c15 font-c6">Essuie</div>
                  <img src="../../assets/images/jpg/essui.jpg" alt="" class="access mt-2">
                </div>
              </div>

              <div class="mt-6 text-c14 text-90" v-if="donnes.accessories === null"> Aucun accessoires supplémentaire </div>
            </div>
          </div>

          <div class="diviser mt-6"/>

          <div class="mt-6 text-c16 text-red" v-if="error !== null">{{ error }}</div>

          <div class="mt-6 text-left flex justify-end">
            <div class="w-2/3 flex items-center justify-end">
              <div class="text-c20">PRIX (Frs) : </div>
              <inputo placeholder="19 000" height="50px" background="#ECECEC" @info="retourPrix" class="w-1/5 ml-6"/>
              <inputo placeholder="1" height="50px" @info="retourQte" class="w-20 ml-6"/>
              <bouton label="Ajouter au panier" :charge="charge" radius="4.5px" class="w-1/3 ml-6" @oga="continuer"/>
            </div>
          </div>
        </div>
      </div>
    </popup-base>
  </section>
</template>

<script>
import PopupBase from '../helper/add/popupfat'
import ic_agence from '../../assets/icons/agenceGray.svg'
import fermer from '../../assets/icons/fermer.svg'
import inputo from '../helper/form/input'
import bouton from '../helper/add/button'

export default {
  name: 'Success',
  components: { PopupBase, inputo, bouton },
  props: {
    activation: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      wantToMakeOffer: false,
      icons: {
        ic_agence,
        fermer
      },
      prix: '',
      qte: '',
      error: null,
      charge: false,
      donnes: null
    }
  },

  created() {
    this.wantToMakeOffer = this.activation
    this.donnes = this.$store.getters.trackTemporal
    console.log(this.donnes)
  },

  methods: {
    fermer () {
      this.$emit('oga', false)
    },

    continuer () {
      this.charge = true
      this.error = null
      if (this.qte !== '' && this.prix !== '') {
        this.donnes.prix = parseInt(this.prix)

        this.donnes.qte = this.qte

        this.$store.dispatch('addToBasket', this.donnes)
        this.fermer()
        this.charge = false
      } else {
        this.charge = false
        this.error = 'Veuillez entrer le prix et la quantité pour continuer'
      }
    },

    retourFile (answer) {
      this.file = answer
    },

    logState (answer) {
      this.wantToMakeOffer = answer
      this.$emit('oga', false)
    },

    retourQte (answer) {
      this.qte = answer
    },

    retourPrix (answer) {
      this.prix = answer
    }
  }
}
</script>

<style lang="scss"  scoped>
    @import "../../assets/styles/sass/mixin";
    .success {
      z-index: 999;
    }
    .cardinal {
      width: 80%;
    }

    .access {
      width: 145.38px;
      height: 68.41px;
      object-fit: cover;
      border-radius: 6px;
    }
    .img {
      width: 312.98px;
      height: 350.35px;
      object-fit: cover;
    }
    .diviser {
      height: 1px;
      background-color: #DDDDDD;
    }

    .success-container {
      font-family: $font-default;
      width: 100%;
      background-color: white;
      height: auto;
      margin: auto;
      border-radius: 12px;
    }
    @media screen and (max-width: 700px){
      .success-container {
        width: 100%;
        height: 100vh;
      }
      .titre {
        font-size: 18px;
      }
      .bloc1 {
        width: 100%;
      }
      .bloc2 {
        width: 100%;
        font-size: 18px;
      }
      .button {
        width: 100%;
        font-size: 16px;
      }
      .cardinal {
        width: 100%;
      }
    }

    @media screen and (max-width: 1024px) and (min-width: 770px){
      .cardinal{
        width: 65%;
      }
      .titre{
        font-size: 18px;
      }
      .button{
        width: 100%;
        height: 55px;
        font-size: 19px;
      }
    }

    @media screen and (max-width: 769px) and (min-width: 701px){
      .cardinal{
        width: 90%;
      }
      .titre{
        font-size: 18px;
      }
      .button{
        width: 100%;
        height: 55px;
        font-size: 19px;
      }
    }
    @media screen and (max-width: 320px){
      .cardinal{
        width: 100%;
      }
      .titre{
        font-size: 14px;
      }
      .button{
        width: 100%;
        height: 55px;
        font-size: 15px;
      }
    }
</style>
